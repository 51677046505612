import { FC, ReactElement } from "react";
import { Carousel, Form, ProgressBar } from "react-bootstrap";
import { FetchWrapper } from "../_models/types";
import { ModuleChapter } from "../_models/types";
import { moduleService } from "../_services";

interface IProps {
    fetchWrapper: FetchWrapper,
    loadedChapter: ModuleChapter
    category: string
    chapterNumber: number
    moduleNumber: number
    getModuleProgress: (chapterNumber: number) => void
    moduleProgress: any
    activeIndex: number
    allModules: any
    setIndex: (index: number) => void
    allModulesStatus: () => void
}
const ModuleChapterComponent: FC<IProps> = ({
    fetchWrapper,
    loadedChapter,
    category,
    chapterNumber,
    moduleNumber,
    getModuleProgress,
    moduleProgress,
    activeIndex,
    allModules,
    setIndex,
    allModulesStatus }) => {

    const completeOffline = async (e?: any) => {
        try {
            await moduleService.completeOffline(fetchWrapper, moduleNumber, loadedChapter.chapterNumber, { slides: loadedChapter.slides - 1, complete: 1 });
            getModuleProgress(chapterNumber)
            allModulesStatus();
        } catch (error) {

        }
    }

    const getModuleSlides = () => {
        let slides: ReactElement[] = [];
        if (loadedChapter) {
            for (let i = 1; i <= loadedChapter.slides + 1; i++) {
                slides.push(<Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={`/media/${category}/chapter${chapterNumber}/slide (${i}).jpg`}
                        alt={`Chapter ${chapterNumber}, slide ${i}`}
                    />
                </Carousel.Item>);
            }
        }
        return slides;
    }

    const getCompletedStatus = (loadedChapter: any) => {
        if (allModules) {
            const _loadchap = allModules.find((module: any) => module.chapter.chapterNumber === loadedChapter.chapterNumber);
            return _loadchap.chapterProgress.completed === _loadchap.chapterProgress.slides;
        }
    }

    const currentSlide = async (eventKey: number, event: any) => {
        if (event.target.className === "carousel-control-next-icon") {
            if (activeIndex === moduleProgress.completed) {
                await moduleService.completeSlide(fetchWrapper, moduleNumber, chapterNumber);
                getModuleProgress(chapterNumber)
            } else {
                setIndex(eventKey)
            }
        }

        if (event.target.className === "carousel-control-prev-icon") {
            setIndex(eventKey === 0 ? 0 : eventKey)
        }
    }

    const getProgress = (loadedChapter: any, module: any) => {
        if (moduleProgress && loadedChapter) {
            const now = Math.round((moduleProgress.completed / (moduleProgress.slides)) * 100);
            if (now === 100 && !moduleProgress.completed) {
                completeOffline();
            }
            return <ProgressBar now={now} label={`${now > 100 ? 100 : now}%`} />;
        }
    }

    return <div className="w-100 module-view__body__assessment">
        <div className="row py-2 header-text-progress">
            <div className="col-8">
                <p>{category && `${category[0].toUpperCase()}${category.slice(1)} Skills: ${loadedChapter.name}`}</p>
            </div>
            <div className="header-text-progress__offline col-4">
                <Form.Check
                    disabled={getCompletedStatus(loadedChapter)}
                    checked={getCompletedStatus(loadedChapter)}
                    reverse
                    type="checkbox"
                    label="Complete Offline"
                    id="offline"
                    onChange={completeOffline}
                />
            </div>
        </div>
        {getProgress(loadedChapter, allModules)}
        {
            <Carousel
                interval={null}
                activeIndex={activeIndex}
                wrap={false}
                keyboard={false}
                touch={false}
                indicators={false}
                onSelect={currentSlide}>
                {
                    getModuleSlides()
                }
            </Carousel>
        }
    </div>
}

export default ModuleChapterComponent;
