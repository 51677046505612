import { useEffect, useReducer, useState } from 'react';
import { decode } from 'base-64';
import { Outlet, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './App.scss';
import AboutUsPage from './pages/aboutUsPage';
import FAQPage from './pages/fAQPage';
import ForgotPasswordPage from './pages/forgotPasswordPage';
import LandingPage from './pages/landingPage';
import ResourcesPage from './pages/resourcesPage';
import ProfilePage from './pages/profilePage';
import MyLearningPage from './pages/myLearningPage';
import SignInPage from './pages/signInPage';
import { PrivateRoute } from './_components/privateRoute.component';
import { initialUserAuthState, UserAuthReducer, UserAuthStateProvider } from './_contexts/userAuth.context';
import useAuth from './_hooks/useAuth';
import { LOGIN } from './_models/types';
import FooterComponent from './_shared/footer.component';
import HeaderComponent from './_shared/header.component';
import { history } from './_helpers/history';
import ModuleViewPage from './pages/moduleViewPage';
import ChangePassword from './pages/changePassword';
import SelfHelpContact from './pages/selfHelpContact';
import { SelfHelp } from './pages/selfHelp';
import { SelfHelpCategory } from './pages/selfHelpCategory';
import { SelfHelpArticle } from './pages/selfHelpArticle';

function App() {
	const { UserAuth } = useAuth();
	const [UserAuthState, dispatchUserAuth] = useReducer(UserAuthReducer, initialUserAuthState);
	const [LoadingUser, setLoadingUser] = useState(true);
	const [searchParams] = useSearchParams()
	const token: string | null = searchParams.get('token')
	history.navigate = useNavigate();
	history.location = useLocation();

	useEffect(() => {
		setLoadingUser(true);
		const payload = JSON.parse(localStorage.getItem('_artw') as string)
		payload && payload.access && dispatchUserAuth({ type: LOGIN, payload })

		if (payload && payload.access) {
			dispatchUserAuth({ type: LOGIN, payload });
		} else if (token) {
			dispatchUserAuth({ type: LOGIN, payload: JSON.parse(decode(token)) });			
			setTimeout(() => history.navigate("my-learning"), 1000)
		}

		setLoadingUser(false);
	}, [UserAuth, token])

	return <UserAuthStateProvider value={{ UserAuthState, dispatchUserAuth }}>
		<div className="App container-fluid">
			<div className="row">
				<HeaderComponent />
			</div>
			<div className="row cs-body">
				{!LoadingUser &&
					<Routes>
						<Route path="/" element={<LandingPage />} />
						<Route path="/about-us" element={<AboutUsPage />} />						
						<Route path="/faq" element={<FAQPage />} />
						<Route path="/forgot-password" element={<ForgotPasswordPage />} />						
						<Route path="/login" element={<SignInPage />} />
						<Route path="/my-learning" element={<PrivateRoute loadingUser={LoadingUser}><Outlet /></PrivateRoute>} >
							<Route index element={<MyLearningPage />} />
							<Route path="/my-learning/resources" element={<ResourcesPage />} />
							<Route path="/my-learning/:category" element={<ModuleViewPage />} />
							<Route path="/my-learning/edit-profile" element={<ProfilePage />} />
							<Route path="/my-learning/edit-profile/change-password" element={<ChangePassword />} />
						</Route>
						<Route path="/self-help">
							<Route index element={<SelfHelp />} />
							<Route path="/self-help/category/:slug" element={<SelfHelpCategory />} />
							<Route path="/self-help/article/:id" element={<SelfHelpArticle />} />
							<Route path="/self-help/log-a-ticket" element={<SelfHelpContact />} />
						</Route>
					</Routes>
				}
			</div>
			<div className="footer row">
				<FooterComponent />
			</div>
		</div>
	</UserAuthStateProvider>
}

export default App;