//Authorization
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export enum UserRoles {
    Global = "global",
    Manager = "manager",
    Trafficker = "trafficker"
}

export enum ModuleTypes {
    money = 1,
    entrepreneurial = 2,
    work = 3,
    people = 4
}

export enum OptionTypes {
    "a" = "a",
    "b" = "b",
    "c" = "c",
    "d" = "d",
    "e" = "e",
    "f" = "f",
    "g" = "g",
    "h" = "h",
}

export type User = {
    username?: string;
    user_id: string;
    token: string;
    role?: UserRoles
} | null;

export type RequestOptions = {
    method: string
    headers: {
        'Content-Type'?: string
        Authorization: string
    }
    body: any,
    mode?: "cors" | "no-cors" | "same-origin"
}

export type RequestDetails = {
    method: string
    token?: string
}

export type ArbitraryType<T = any> = T | string

export type FetchWrapper = {
    get: (url: string, body?: any) => Promise<any>;
    post: (url: string, body: any) => Promise<any>;
    patch: (url: string, body: any) => Promise<any>;
    put: (url: string, body: any) => Promise<any>;
    delete: (url: string, body: any) => Promise<any>;
}

export type LoginDetails = { username: string, password: string };

export type SelfHelpContact = {
    email: string,
    name: string,
    surname: string,
    message: string,
    topic: string,
}

export type LearningModule = {
    chapters: ModuleChapter[]
    link: string
    name: string
}

export type LearningModuleSummary = {
    name: string
    completed: number
    is_scorm: false
    total: number
}

export interface IAction { type: string, payload?: any }

export type UserDetails = {
    id: number,
    first_name: string,
    last_name: string,
    email: string,
    title: string,
    dob: string,
    address: string,
    location: string,
    country: string,
    town: string,
    state: string,
    race: string,
    contact_number: string,
    consent_given: boolean,
    terms_accepted: boolean,
    gender: string,
    username: string,
    confirmed: boolean,
    category: string,
    reference_source: string,
    reference_other: string,
    image: string,
    is_admin: boolean
}

export type UserProfile = {
    id: number,
    city: string,
    connected: boolean,
    gender: string,
    name: string,
    race: string,
    surname: string,
    courses: string[],
    experiences: string[],
    skills: string[],
    show_contact: boolean,
    created: string,
    modified: string,
    bio: string,
    sa_citizen: string,
    image: string,
    creator: number
}

export type ModuleChapter = {
    name: string,
    slides: number,
    completed: number,
    chapterNumber: number
}

export type ModuleFiles = {
    name: string,
    moduleGuide: string,
    files: string[],
    assessment: string,
    answer: string
}
