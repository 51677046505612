import { SelfHelpContact, FetchWrapper } from "../_models/types";
const baseURL = process.env.REACT_APP_API_URL as string;

const getSelfHelp = async (fetchWrapper: FetchWrapper) => fetchWrapper.get(`${baseURL}/v3/self_help/self_help/`);
const getSelfHelpCategory = async (category: string, fetchWrapper: FetchWrapper) => fetchWrapper.get(`${baseURL}/v3/self_help/self_help/${category}/`);
const getSelfHelpArticle = async (id: string, fetchWrapper: FetchWrapper) => fetchWrapper.get(`${baseURL}/v3/self_help/${id}/`);
const selfHelpContact = async (formDetails: SelfHelpContact, fetchWrapper: FetchWrapper) => fetchWrapper.post(`${baseURL}/v1/contact/`, formDetails);

export const selfHelpService = {
    getSelfHelp,
    getSelfHelpCategory,
    getSelfHelpArticle,
    selfHelpContact,
}
