import { useState } from "react";
import { Button, Modal, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const FooterComponent = () => {
    const [showTandC, setshowTandC] = useState(false)
    const [showPrivacyPolicy, setPrivacyPolicy] = useState(false)
    const [showFSP, setFSP] = useState(false)

    return <div className="cs-footer"><p>
        <Modal show={showTandC} onHide={() => setPrivacyPolicy(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Please take note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                By clicking Accept, you acknowledge that you are leaving the Absa Ready To Work website
                and will be redirected to the Absa website. Please review the terms and conditions
                provided by Absa Bank, as they govern your use of their services. Absa Ready To Work is
                not responsible for the content or policies on the Absa website.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setshowTandC(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    window.open('https://www.absa.co.za/legal-and-compliance/terms-of-use/', '_blank')
                    setshowTandC(false)
                }
                }>
                    Accept
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showPrivacyPolicy} onHide={() => setPrivacyPolicy(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Please take note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                By clicking Accept, you acknowledge that you are leaving the Absa Ready To Work website
                and will be redirected to the Absa website. Please review the privacy policy provided by
                Absa Bank, as it outlines how your personal information is collected, used, and protected.
                Absa Ready To Work is not responsible for the privacy practices or policies on the Absa
                website.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setPrivacyPolicy(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    window.open('https://www.absa.co.za/legal-and-compliance/privacy-statement/', '_blank')
                    setPrivacyPolicy(false)
                }
                }>
                    Accept
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showFSP} onHide={() => setFSP(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Please take note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                By clicking Accept, you acknowledge that you are leaving the Absa Ready To Work website
                and will be redirected to the Absa website. Please review the financial service
                provider information provided by Absa Bank, as it contains important details about their
                services and regulatory information. Absa Ready To Work is not responsible for the content
                or accuracy of the financial service provider information on the Absa website.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setFSP(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    window.open('https://www.absa.co.za/about-us/absa-bank/corporate-information/', '_blank')
                    setFSP(false)
                }
                }>
                    Accept
                </Button>
            </Modal.Footer>
        </Modal>
        <span className="link" onClick={() => setshowTandC(true)}>
            Terms & Conditions
        </span> |{" "}
        <span>
            <Nav.Link to="/faq" as={Link} eventKey="faq" className="d-inline link">
                Frequently Asked Questions
            </Nav.Link>
        </span> |{" "}
        <span className="link" onClick={() => setPrivacyPolicy(true)}>Privacy Policy</span> |  © Copyright. Absa Bank Limited, Registration number 1986/004794/06. All rights reserved |
        <span className="link" onClick={() => setFSP(true)}> Authorised Financial Services Provider and a registered credit provider (NCRCP7)</span> </p></div>
}

export default FooterComponent;
