import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import ModuleComponent from "../_components/module.component";
import UserAuthStateContext from "../_contexts/userAuth.context";
import useAPI from "../_hooks/useAPI";
import { LearningModule, LearningModuleSummary } from "../_models/types";
import { moduleService, scormService, userService } from "../_services";
import { history } from '../_helpers/history';
import { sleep } from "../_helpers/sleep";
const MyLearningPage = () => {
    const [scormDetails, setScormDetails] = useState<any>(null)
    const [userDetails, setUserDetails] = useState<any>(null)
    const [moduleDetails, setModuleDetails] = useState<LearningModule[] | null>(null)
    const [moduleSummary, setModuleSummary] = useState<LearningModuleSummary[] | null>(null)
    const { UserAuthState } = useContext(UserAuthStateContext);
    const fetchWrapper = useAPI();

    useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);
                setUserDetails(userDetails);
            };
        }
        getUser();
    }, [UserAuthState])

    useEffect(() => {
        const getModule = async () => {
            if (UserAuthState) {
                const webModule = await moduleService.getModuleInformation(fetchWrapper);
                const webModuleSummary = await moduleService.getModuleSummary(fetchWrapper);
                setModuleSummary(webModuleSummary);
                setModuleDetails(webModule);
            };
        }
        getModule();
    }, [])

    useEffect(() => {
        const getScormDetails = async () => {
            if (UserAuthState) {
                const inProgress = await scormService.getInProgressCount(fetchWrapper);
                const completed = await scormService.getCompletedCount(fetchWrapper);
                setScormDetails({inProgress: inProgress, completed: completed});
            };
        }
        getScormDetails();
    }, [])

    useEffect(() => {
        (async () => {            
            await sleep(1500)
            await scormService.updateRtwUserType(fetchWrapper) 
       })()
    }, [])

    const chaptersInProgress = (md: LearningModule) => {
        const moduleNotStarted = md.chapters
            .map(chapter => chapter.completed === 0)
            .filter(Boolean).length;
        if (moduleNotStarted === md.chapters.length) return 0;
        return md.chapters
            .map(ch => ch.completed >= 0 && ch.completed < ch.slides)
            .filter(Boolean).length
    }

    const chaptersCompleted = (md: LearningModule) => {
        return moduleSummary?.find(mod => mod.name === md.name)?.completed as number;
    }

    const getModulesInProgress = (module: LearningModule[]) => {
        let counter = 0;
        module.forEach(md => {
            if (chaptersInProgress(md))
                counter++;
        })
        counter += (scormDetails?.inProgress ?? 0);
        return counter;
    }

    const getModulesCompleted = (module: LearningModule[]) => {
        let counter = 0;
        module.forEach(md => {
            if (md.chapters.map(ch => ch.completed === ch.slides).filter(Boolean).length === md.chapters.length)
                counter++;
        })
        counter += (scormDetails?.completed ?? 0);
        return counter;
    }

    return <>
        <div className="col-12 resources">
            <div className="row">
                <div className="hero-image">
                    <img
              src='/images/L_ABOUT_us.jpg'
              alt=''
              className='w-100 img-fluid'
            />
                    <div className="h-100 w-100 hero-image__title">
                        <div className="container text-center">
                        <h3>
                            Welcome Back
                            </h3>
                            </div>
                    </div>
                </div>
                <div className="col-12 col-xl-8 offset-xl-2 resources__body">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="col-12 resources__profile_info sticky-lg-top">
                                {
                                    userDetails ?
                                        <div className="col-12 resources__inner-container">
                                            <div className="row">
                                                <div className="col-4 resources__profile_info__image">
                                                    <BsFillPersonFill />
                                                </div>
                                                <div className="col-8 resources__profile_info__user_info">
                                                    <p className="username">{`${userDetails.first_name} ${userDetails.last_name}`}</p>
                                                    <p className="email">{userDetails.email}</p>
                                                </div>
                                            </div>
                                        </div> :
                                        "Loading"
                                }
                                <div className="col-12 resources__module-progress">
                                    {
                                        moduleDetails &&
                                        <ul className="row">
                                            <li className="col-12">
                                                <div className="row">
                                                    <div className="col-4 resources__module-progress__number">
                                                        <p>{moduleDetails.length}</p>
                                                    </div>
                                                    <div className="col-8 resources__module-progress__text">
                                                        <p>Registered Modules</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="col-12">
                                                <div className="row">
                                                    <div className="col-4 resources__module-progress__number">
                                                        <p>{getModulesInProgress(moduleDetails)}</p>
                                                    </div>
                                                    <div className="col-8 resources__module-progress__text">
                                                        <p>Modules in Progress</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="col-12">
                                                <div className="row">
                                                    <div className="col-4 resources__module-progress__number">
                                                        <p>{getModulesCompleted(moduleDetails)}</p>
                                                    </div>
                                                    <div className="col-8 resources__module-progress__text">
                                                        <p>Modules Completed</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="col-12 resources__module-button mt-3">
                                    <Button variant="primary" onClick={() => history.navigate(`my-learning/edit-profile`)}>Manage Profile</Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            {
                                moduleDetails && moduleDetails.map((moduleItem, indx) => <ModuleComponent
                                    key={indx}
                                    module={moduleItem}
                                    moduleSummary={moduleSummary?.find(mod => mod.name === moduleItem.name) as LearningModuleSummary}
                                    chaptersInProgress={chaptersInProgress(moduleItem)}
                                    chaptersCompleted={chaptersCompleted(moduleItem)}
                                />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default MyLearningPage;
