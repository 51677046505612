import { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserAuthStateContext from "../_contexts/userAuth.context";
import useAPI from "../_hooks/useAPI";
import { LOGOUT, UserDetails } from "../_models/types";
import { userService } from "../_services";
import { useCommonContext } from "../_contexts/common.context";
import { useBreakpoints } from "../_hooks/useBreakpoints";

const HeaderComponent = () => {
    const [activeLink, setActiveLink] = useState("/")
    const { isMdDown } = useBreakpoints()
    const location = useLocation()
    const navigate = useNavigate()

    const { reloadPage } = useCommonContext()
    const { UserAuthState, dispatchUserAuth } = useContext(UserAuthStateContext);
    const [user, setUser] = useState<UserDetails | null>(null);
    const [show, setShow] = useState(false);
    const fetchWrapper = useAPI();

    useEffect(() => {
        setActiveLink(location.pathname)
    }, [location]);

    useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);
                setUser({ ...userDetails });
            };
        }
        getUser();
    }, [UserAuthState]);

    const toggleOffCanvas = (state: boolean) => {
        setShow(state);
    };

    const expand = "xl";

    return <>
        <Navbar key={expand} expand={expand} className="fixed-top cs-header" >
            <Container fluid>
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <div className="ps-1">
                        <Navbar.Brand className="align-content-start d-flex">
                            <Nav.Link to="/" as={Link} eventKey="home" >
                                {isMdDown ? (
                                    <div className="cs-header__linkContent clientIcon" style={{ width: 48 }}>
                                        <img src="/images/absa-logo.svg" width={48} height={48} alt="Absa Logo" />
                                    </div>
                                ) : (
                                    <div className="cs-header__linkContent clientIcon">
                                        <img src="/images/absa-logo-story-matters.svg" width={105} height={48} alt="Absa Logo" />
                                    </div>
                                )}
                            </Nav.Link>
                            <Nav.Link to="/" as={Link} eventKey="home" className="align-content-start clientSiteName d-flex">
                                <div className="linkContent ms-2">
                                    <p className="siteName text-left">ReadytoWork <br />e-Learning Platform</p>
                                </div>
                            </Nav.Link>
                        </Navbar.Brand>
                    </div>

                    <div>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            show={show}
                            onHide={toggleOffCanvas}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    ReadytoWork
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-center flex-grow-1 pe-1" fill activeKey="/">
                                    <Nav.Link to="/" as={Link} eventKey="home" onClick={() => toggleOffCanvas(false)}>
                                        <div className={`linkContent ${activeLink === "/" ? "activeLink" : ""}`}>
                                            Home
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link to="/about-us" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="about-us">
                                        <div className={`linkContent ${activeLink === "/about-us" ? "activeLink" : ""}`}>
                                            About Us
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link to="/self-help" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="self-help">
                                        <div className={`linkContent ${activeLink.includes('/self-help') ? "activeLink" : ""}`}>
                                            Self Help
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link to="/faq" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="faq">
                                        <div className={`linkContent ${activeLink.includes('/faq') ? "activeLink" : ""}`}>
                                            FAQs
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link to="/my-learning" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="resources">
                                        <div className={`linkContent ${activeLink === "/my-learning" ? "activeLink" : ""}`}>
                                            {user ? 'My Learning' : 'Login'}
                                        </div>
                                    </Nav.Link>
                                    <Nav.Link to="/my-learning/resources" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="resources">
                                        <div className={`linkContent ${activeLink === "/my-learning/resources" ? "activeLink" : ""}`}>
                                            Resources
                                        </div>
                                    </Nav.Link>
                                    {
                                        user && user.is_admin &&
                                        <Nav.Link eventKey="admin" onClick={() => window.open(`${process.env.REACT_APP_ADMIN_CONSOLE}`, '_blank')}>
                                            <div className="linkContent">
                                                Admin
                                            </div>
                                        </Nav.Link>
                                    }
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </div>
                </div>

                <div className="col-3 justify-content-end d-flex pe-1">
                    {UserAuthState || user ?
                        <div className="px-3 d-flex align-items-center">
                            <div className="position-relative ps-4 d-lg-flex">
                                <div className="link d-flex flex-row" onClick={() => {
                                    dispatchUserAuth({ type: LOGOUT })
                                    reloadPage()
                                }}>
                                    <RiLogoutCircleRLine size="20px" className="headerIcon" /> &nbsp;&nbsp; <span className="ml-2">Logout</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="ps-3 d-flex align-items-center">
                            <button onClick={() => navigate("/login")} className="mr-2 ab-icon-button">
                                <img src="/images/icon-login.svg" alt="Login" width={22} height={22} className="mr-2" />
                                <span className="ms-2">Login</span></button>
                        </div>}
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => toggleOffCanvas(true)} />
                </div>
            </Container>
        </Navbar>
    </>
}

export default HeaderComponent;
