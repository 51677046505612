import { useState, useEffect } from 'react'

interface Breakpoints {
	isXs: boolean
	isSm: boolean
	isMd: boolean
	isLg: boolean
	isXl: boolean
	isXxl: boolean
	isSmUp: boolean
	isMdUp: boolean
	isLgUp: boolean
	isXlUp: boolean
	isXxlUp: boolean
	isSmDown: boolean
	isMdDown: boolean
	isLgDown: boolean
	isXlDown: boolean
}

const useBreakpoints = (): Breakpoints => {
	const [breakpoints, setBreakpoints] = useState<Breakpoints>({
		isXs: false,
		isSm: false,
		isMd: false,
		isLg: false,
		isXl: false,
		isXxl: false,
		isSmUp: false,
		isMdUp: false,
		isLgUp: false,
		isXlUp: false,
		isXxlUp: false,
		isSmDown: false,
		isMdDown: false,
		isLgDown: false,
		isXlDown: false,
	})

	const handleResize = () => {
		const width = window.innerWidth
		setBreakpoints({
			isXs: width >= 0 && width < 576,
			isSm: width >= 576 && width < 768,
			isMd: width >= 768 && width < 992,
			isLg: width >= 992 && width < 1200,
			isXl: width >= 1200 && width < 1400,
			isXxl: width >= 1400,
			isSmUp: width >= 576,
			isMdUp: width >= 768,
			isLgUp: width >= 992,
			isXlUp: width >= 1200,
			isXxlUp: width >= 1400,
			isSmDown: width < 768,
			isMdDown: width < 992,
			isLgDown: width < 1200,
			isXlDown: width < 1400,
		})
	}

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return breakpoints
}

export { useBreakpoints }
