import { BsDownload } from "react-icons/bs";
import { ReactComponent as Moneybag } from '../assets/absa_icons/Icon_Moneybag_Rand_SVG_Black.svg';
import { ReactComponent as People } from '../assets/absa_icons/Icon_Group_People_SVG_Black.svg';
import { ReactComponent as Entrepreneur } from '../assets/absa_icons/Icon_Training_and_Development_SVG_Black.svg';
import { ReactComponent as Work } from '../assets/absa_icons/Icon_Office chair_SVG_Black.svg';
import { useContext, useEffect, useState } from "react";
import UserAuthStateContext from "../_contexts/userAuth.context";
import { userService } from "../_services";
import useAPI from "../_hooks/useAPI";
import { UserDetails } from "../_models/types";

const ResourcesPage = () => {
    const { UserAuthState } = useContext(UserAuthStateContext);
    const [user, setUser] = useState<UserDetails | null>(null)
    const fetchWrapper = useAPI();

    useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);
                setUser({ ...userDetails });
            };
        }
        getUser();
    }, [UserAuthState]);

    const downloadTxtFile = async (filename: string) => {
        const downloadfile = (await import(`../assets/module_guides/${filename}`)).default;
        const element = document.createElement("a");
        element.href = downloadfile;
        element.download = filename;
        element.click();
    };
    return <>

        <div className="col-12 about-us">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/J_FAQ.jpg" alt="" className="w-100 img-fluid" />
                    <div className="h-100 w-100 hero-image__title">
                        <div className="container text-center">
                        <h3>
                            Resources
                            </h3>
                            </div>
                    </div>
                </div>

                <div className="col-12 col-lg-10 offset-lg-1 myLearning">
                    <div className="row myLearning__body">
                        {user && <>
                            <div className="col-12 col-lg-6">
                                <div className="col-12 myLearning__module myLearning__module--money">
                                    <div className="row">
                                        <div className="col-12 myLearning__module__header">
                                            <div className="row">
                                                <div className="col-10 myLearning__module__header-text">
                                                    <p>Money skills</p>
                                                </div>
                                                <div className="col-2  myLearning__module__icon">
                                                    <div className="icon-container">
                                                        <Moneybag className="icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-lg-7 myLearning__module__details">
                                                    <ul className="row">
                                                        <li>Spending and saving wisely</li>
                                                        <li>A step-by-step guide to creating your own budget</li>
                                                        <li>Understanding and using credit to your advantage</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-lg-5 myLearning__module__downloads">
                                                    {user.is_admin &&
                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("67508 RTW FACGUIDE ONLINE MONEY SKILLS.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Facilitator Guide</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66540 RTW MOD3 Topic1 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 1</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66541 RTW MOD3 Topic2 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 2</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66542 RTW MOD3 Topic3 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 3</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {user.is_admin && <>
                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("RTW Assessment MOD3 money.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Money Assessment</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("RTW ANSWER MOD3 money_R1.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Money Answer</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("Certificate Money Skills.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Certificate</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6">
                                <div className="col-12 myLearning__module myLearning__module--entrepreneurial">
                                    <div className="row">
                                        <div className="col-12 myLearning__module__header">
                                            <div className="row">
                                                <div className="col-10 myLearning__module__header-text">
                                                    <p>Entrepreneurial skills</p>
                                                </div>

                                                <div className="col-2  myLearning__module__icon">
                                                    <div className="icon-container">
                                                        <Entrepreneur className="icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-lg-7 myLearning__module__details">
                                                    <ul className="row">
                                                        <li>Considering self-employment</li>
                                                        <li>Key things new business owners should know about the market</li>
                                                        <li>Setting up your business</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-lg-5 myLearning__module__downloads">
                                                    {user.is_admin &&
                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("FGUIDE_ENTREPRENEURIAL.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Facilitator Guide</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66544 RTW MOD4 Topic1 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 1</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66545 RTW MOD4 Topic2 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 2</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66546 RTW MOD4 Topic3 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 3</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {user.is_admin && <>
                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("RTW Assessment MOD4 entrepreneurial.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Entrepreneurial Assessment</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("RTW ANSWER MOD4 entrpreneurial_R1.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Entrepreneurial Answer</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("Certificate Entrepreneurial Skills.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Certificate</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6">
                                <div className="col-12 myLearning__module myLearning__module--work">
                                    <div className="row">
                                        <div className="col-12 myLearning__module__header">
                                            <div className="row">
                                                <div className="col-10 myLearning__module__header-text">
                                                    <p>Work skills</p>
                                                </div>

                                                <div className="col-2  myLearning__module__icon">
                                                    <div className="icon-container">
                                                        <Work className="icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-lg-7 myLearning__module__details">
                                                    <ul className="row">
                                                        <li>The world of work: How to get in and stay in!</li>
                                                        <li>Creating a CV you can be proud of</li>
                                                        <li>Identifying, pursuing and getting what you want</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-lg-5 myLearning__module__downloads">
                                                    {user.is_admin &&
                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("66534 F_Guide MOD1 work skills FINAL.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Facilitator Guide</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66457 RTW MOD1 Topic1 FINAL.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 1</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66458 RTW MOD1 Topic2 FINAL.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 2</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66518 RTW MOD1 Topic3 FINAL.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 3</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66519 RTW MOD1 Topic4 FINAL.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 4</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {user.is_admin && <>
                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("RTW Assessment MOD1 work.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Work Assessment</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("RTW ANSWER MOD1 work_R1.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Work Answer</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("Certificate Work Skills.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Certificate</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6">
                                <div className="col-12 myLearning__module myLearning__module--people">
                                    <div className="row">
                                        <div className="col-12 myLearning__module__header">
                                            <div className="row">
                                                <div className="col-10 myLearning__module__header-text">
                                                    <p>People skills</p>
                                                </div>
                                                <div className="col-2  myLearning__module__icon">
                                                    <div className="icon-container">
                                                        <People className="icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-lg-7 myLearning__module__details">
                                                    <ul className="row">
                                                        <li>Developing effective people skills</li>
                                                        <li>Developing effective communication skills</li>
                                                        <li>Understanding emotional intelligence</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-lg-5 myLearning__module__downloads">
                                                    {user.is_admin &&
                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("66538 FACGuideMOD2 People skills FINAL.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Facilitator Guide</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66524 RTW MOD2 Topic1 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 1</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66527 RTW MOD2 Topic2 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 2</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 download-item" onClick={() => downloadTxtFile("66523 RTW MOD2 Topic3 Final.pdf")}>
                                                        <div className="row">
                                                            <div className="col-3 download-item__icon">
                                                                <BsDownload />
                                                            </div>
                                                            <div className="col-9 download-item__text">
                                                                <p>Topic 3</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {user.is_admin && <>
                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("RTW Assessment MOD2 people.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>People Assessment</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("RTW ANSWER MOD2 people_R1.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>People Answer</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 download-item" onClick={() => downloadTxtFile("Certificate People Skills.pdf")}>
                                                            <div className="row">
                                                                <div className="col-3 download-item__icon">
                                                                    <BsDownload />
                                                                </div>
                                                                <div className="col-9 download-item__text">
                                                                    <p>Certificate</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ResourcesPage;
