import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import AlertWrapper from "../_helpers/alert-wrapper";
import useAPI from "../_hooks/useAPI";
import { SelfHelpContact as SelfHelpContactProps } from "../_models/types";
import { selfHelpService, userService } from "../_services";
import { useCharacterCount } from "../_hooks/useCharacterCount";
import UserAuthStateContext from "../_contexts/userAuth.context";

const SelfHelpContact = () => {
    const [errorMsg, setErrorMsg] = useState<string | null>(null)    
    const [successMsg, setSuccessMsg] = useState<string | null>(null)
    const { UserAuthState } = useContext(UserAuthStateContext)
    const [formDetails, setFormDetails] = useState<SelfHelpContactProps>({
        surname: "",
        email: "",
        topic: "",
        message: "",
        name: ""
    })

    const [canSubmit, setCanSubmit] = useState(false);
    const fetchWrapper = useAPI()
    const { currentLength, limit } = useCharacterCount({ text: formDetails.message })

    useEffect(() => {
        formDetails ?
            setCanSubmit(
                !!(formDetails.message && formDetails.message.length) &&
                !!(formDetails.name && formDetails.name.length) &&
                !!(formDetails.surname && formDetails.surname.length) &&
                !!(formDetails.topic && formDetails.topic.length) &&
                !!(formDetails.email && formDetails.email.length)
            ) : setCanSubmit(false)
    }, [formDetails])


useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);                
                if (userDetails?.first_name) {
                    setFormDetails({
                        ...formDetails,
                        name: userDetails?.first_name,
                        surname: userDetails?.last_name,
                        email: userDetails?.email,
                    })
                }                
            }
        }
        getUser()
    }, [UserAuthState])    
    
    const submitForm = async () => {
        try {
            await selfHelpService.selfHelpContact(formDetails, fetchWrapper);
            setSuccessMsg("Message successfully sent");
            setFormDetails({ surname: "", email: "", topic: "", message: "", name: "" })
        } catch (err: any) {
            const errors = Object.keys(err);
            let errorString = "";

            if (errors.length) {
                errors.forEach(er => errorString += `${err[er]}\n`);
            } else { errorString = err };

            setErrorMsg(errorString);
        }
    }

    return <>
        <div className="col-12 faq">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/L_ABOUT_us.jpg" alt="" className="w-100 img-fluid" />
                    <div className="h-100 w-100 hero-image__title">
                        <div className="container text-center">
                        <h3>
                            Log a Support Ticket
                        </h3></div>
                    </div>
                </div>
                <div className="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4 h-100">
                    <div className={`card`}>
                        <div className="card-body">
                            {
                                errorMsg && <AlertWrapper message={errorMsg} variant="danger" closeMsg={() => setErrorMsg(null)} />
                            }
                            {
                                successMsg && <AlertWrapper message={successMsg} variant="success" closeMsg={() => setSuccessMsg(null)} />
                            }
                            <Form>
                                <div className="row">
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formDetails.name}
                                            onChange={(e) => setFormDetails({ ...formDetails, name: e.target.value } as SelfHelpContactProps)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="surname">
                                        <Form.Label>Surname</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formDetails.surname}
                                            onChange={(e) => setFormDetails({ ...formDetails, surname: e.target.value as string } as SelfHelpContactProps)} />
                                    </Form.Group>
                                </div>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={formDetails.email}
                                        onChange={(e) => setFormDetails({ ...formDetails, email: e.target.value as string } as SelfHelpContactProps)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Subject Heading</Form.Label>
                                    <Form.Control
                                        type="topic"
                                        value={formDetails.topic}
                                        onChange={(e) => setFormDetails({ ...formDetails, topic: e.target.value as string } as SelfHelpContactProps)} />                                                                       
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={4} maxLength={limit}
                                        value={formDetails.message}
                                        onChange={(e) => setFormDetails({ ...formDetails, message: e.target.value } as SelfHelpContactProps)} />
                                    <div className={`d-flex flex-row justify-content-end`} style={{ fontSize: '13px'}}>
                                    <span style={{ opacity: 0.8 }}>{currentLength} / </span>
                                    <span>{limit}</span>
                                </div>
                                </Form.Group>
                                
                                <Button variant="primary" type="button" onClick={submitForm} disabled={!canSubmit}>
                                    Send Message
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <br />
        <br />
        <br />
    </>
}

export default SelfHelpContact;
