import React, { useEffect, useState } from "react";
import { selfHelpService } from "../_services";
import { uniqBy } from 'lodash'
import { ArbitraryType } from "../_models/types";
import useAPI from "../_hooks/useAPI";
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { sleep } from "../_helpers/sleep";

export const SelfHelp: React.FC = () => {
    const api = useAPI()
    const navigate = useNavigate()
    const [selfHelpTopics, setSelfHelpTopics] = useState<ArbitraryType[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    async function getData() {
        setIsLoading(true)
        await sleep(1500)
        try {
            const res = await selfHelpService.getSelfHelp(api)
            setSelfHelpTopics(uniqBy(res, 'title').filter((item) => 'title' in item))
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className="col-12">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/L_ABOUT_us.jpg" alt="" className="w-100 img-fluid" />
                    <div className="h-100 w-100 hero-image__title">
                        <div className="container text-center">
                            <h3>Self Help</h3>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4 h-100">
                        <div className={`card`}>
                            <div className="card-body">
                                {
                                    isLoading
                                        ?
                                         <div className={`d-flex justify-content-center align-items-center w-100`} style={{ minHeight: 100 }}>
                                            <Spinner animation="border" size="sm" variant="primary" />
                                        </div>                                      
                                        :
                                        <ul className="list-group list-group-flush">
                                            {selfHelpTopics.length > 0 ? (
                                                <React.Fragment>
                                                    {selfHelpTopics.map((topic: ArbitraryType, i: number) => (
                                                        <li
                                                            onClick={() => {
                                                                navigate(`/self-help/category/${topic?.category}`, {
                                                                    state: {
                                                                        id: topic?.id,
                                                                        category: topic?.category,
                                                                        title: topic?.title,
                                                                    }
                                                                })
                                                            }}
                                                            key={i} className={`cursor-pointer list-group-item list-group-item-action d-flex justify-content-between align-items-center`}>
                                                            {topic?.title}
                                                            <BsChevronRight />
                                                        </li>
                                                    ))}
                                                </React.Fragment>
                                            ) : null}
                                        </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}