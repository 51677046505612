import { useEffect, useState } from 'react'

interface UseCharacterCountProps {
	limit?: number
	text: string
}

interface UseCharacterCountResult {
	currentLength: number
	limit: number
}

export const useCharacterCount = ({ limit = 1024, text }: UseCharacterCountProps): UseCharacterCountResult => {
	const [currentLength, setCurrentLength] = useState(0)

	useEffect(() => {
		setCurrentLength(text.length)
	}, [text])

	return { currentLength, limit }
}
