import { useContext, useEffect, useState } from "react";
import UserAuthStateContext from "../_contexts/userAuth.context";
import useAPI from "../_hooks/useAPI";
import { UserDetails, UserProfile } from "../_models/types";
import { userService } from "../_services";
import { profileService } from "../_services/profile.service";
import { ReactComponent as Credit } from '../assets/absa_icons/Icon-Credit-Protection-100x100.svg';
// import { ReactComponent as TravelBag } from '../assets/absa_icons/Icon-Travel-bag-100x100.svg';
import { Alert, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const ManageAccount = () => {
    const [user, setUser] = useState<UserDetails | null>(null);
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const { UserAuthState } = useContext(UserAuthStateContext);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    // const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const fetchWrapper = useAPI();

    useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);
                const userProfile = await profileService.getUserProfile(UserAuthState.user_id, fetchWrapper);
                setProfile(userProfile);
                setUser(userDetails);
            };
        }
        getUser();
    }, [UserAuthState])

    // const changeUserRole = async () => {
    //     try {
    //         await apiService.makeFacilitator({}, fetchWrapper);
    //         setShowSuccess(true);
    //     } catch (error) {
    //         setErrorMessage(error as string)
    //         setShowError(true);
    //     }
    // }

    return <>
        {user && profile &&
            <>
                <div className="col-12 manage-account">
                    {showError && <Alert variant="danger" onClose={() => setShowError(!showError)} dismissible>
                        {/* {errorMessage} */}
                    </Alert>}
                    {showSuccess && <Alert variant="success" onClose={() => setShowSuccess(!showSuccess)} dismissible>
                        Account successfully set to Facilitator
                    </Alert>}
                    <h5>Manage your account. Here you can reset your password, or become a ready to work facilitator to help the youth.</h5>
                    <div className="row">
                        {/* <div className="col-6 manage-account__option">
                            <div className="col-12 manage-account__option__icon">
                                <TravelBag />
                            </div>
                            <div className="col-12 manage-account__option__head">
                                <p>Become A Facilitator</p>
                            </div>
                            <div className="col-12 manage-account__option__text">
                                <p>Volunteer to be a ReadytoWork Facilitator.</p>
                            </div>
                            <div className="col-12 manage-account__option__link">
                                <p onClick={changeUserRole}>Become A Facilitator {">"}</p>
                            </div>
                        </div> */}
                        <div className="col-12 manage-account__option">
                            <div className="col-12 manage-account__option__icon">
                                <Credit />
                            </div>
                            <div className="col-12 manage-account__option__head">
                                <p>Reset Your Password</p>
                            </div>
                            <div className="col-12 manage-account__option__text">
                                <p>Change your ReadytoWork log in password.</p>
                            </div>
                            <div className="col-12 manage-account__option__link">
                                <Nav.Link to="/my-learning/edit-profile/change-password" as={Link} eventKey="faq" className="d-inline link">
                                    Reset  {">"}
                                </Nav.Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
}

export default ManageAccount;
