import { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import UserAuthStateContext from "../_contexts/userAuth.context";
import AlertWrapper from "../_helpers/alert-wrapper";
import useAPI from "../_hooks/useAPI";
import { LOGIN } from "../_models/types";
import { authenticationService } from "../_services";
import { history } from '../_helpers/history';
import * as amplitude from '@amplitude/analytics-browser';


const SignInPage = () => {
    const userAuth = useContext(UserAuthStateContext);
    const [logInDetails, setlogInDetails] = useState({ username: "", password: "" });
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const fetchWrapper = useAPI();

    const submitForm = async () => {
        try {
            let id: string = "guest"
            const authPayload = await authenticationService.signInUser(logInDetails, fetchWrapper)            
           
            userAuth.dispatchUserAuth({ type: LOGIN, payload: authPayload });
            localStorage.setItem('_artw', JSON.stringify(authPayload));
            let access_token = authPayload["access"]
            let encoded_payload = access_token.split(".")[1]
            // Create a userid - pad with 0's if it's too short
            id = JSON.parse(atob(encoded_payload))["user_id"].toString();

            if (id.length < 5) {
                id = "0".repeat(5 - id.length) + id;
            }
            // Track users that have logged in
            amplitude.setUserId(id);
           
            history.navigate("my-learning")
        } catch (error) {
            setErrorMsg(error as string);
        }
    }

    const handleKeyDown = (event: any) => {
        event.key === "Enter" && submitForm();
    }

    return <>
        <div className="col-12 signin">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/L_ABOUT_us.jpg" alt="" className="w-100 img-fluid" />
                    <div className="h-100 w-100 hero-image__title">
                       <div className="container text-center">
                            <h3>Sign in</h3>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    {
                        errorMsg && <AlertWrapper message={errorMsg} variant="danger" closeMsg={() => setErrorMsg(null)} />
                    }
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setlogInDetails({ ...logInDetails, username: e.target.value })} />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password <span className="signin__forgot-text" onClick={() => history.navigate("forgot-password")}>Forgot?</span></Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setlogInDetails({ ...logInDetails, password: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={submitForm}>
                            Sign In
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    </>
}

export default SignInPage;
