import { Accordion } from "react-bootstrap";

const FAQPage = () => {
    return <>
        <div className="col-12 faq">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/L_ABOUT_us.jpg" alt="" className="w-100 img-fluid" />

                    <div className="h-100 w-100 hero-image__title">
                        <div className="container text-center">
                            <h3>
                                Questions Frequently Asked By Users
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-8 offset-lg-2">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is ReadytoWork?</Accordion.Header>
                            <Accordion.Body>
                                If you’re ready to work, Absa ReadytoWork is the resource for you. It is a Pan-African education and skills development initiative designed by Absa to specifically help prepare young people for the critical transition from learning to earning, that is, from education into the world of work. Through access to online career guidance, skills-related short courses and job search tools, among other resources, it will help bolster your skills and employability.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>What does ReadytoWork offer?</Accordion.Header>
                            <Accordion.Body>
                                Absa ReadytoWork provides access to skills development courses that will increase employability, access to job search tools and a community of peers for network opportunities.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Is ReadytoWork accredited?</Accordion.Header>
                            <Accordion.Body>
                                Absa ReadytoWork is not accredited by any qualification authority for academic recognition as it primarily aims to provide a basic level of understanding and comprehension on specific topics in the form of short courses. However, given that it is an established initiative by a credible financial institution and Financial Services Provider, the Absa ReadytoWork initiative will boost your employability opportunities when you can demonstrate that you have successfully completed the courses.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Who is ReadytoWork aimed at?</Accordion.Header>
                            <Accordion.Body>
                                If you’re 18 to 35 years old, you have the aptitude, attitude and ambition to achieve more, and you’re ready to learn, to make connections and to start your career journey, Absa ReadytoWork will provide you with pathways to better develop your employment or self-employment prospects. And because we recognise that young people leave the education system at various ages and stages of learning, Absa ReadytoWork’s interventions and support are tailored to support individuals across various transitions into work.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>How long will it take to complete a short course?</Accordion.Header>
                            <Accordion.Body>
                                As the courses are self-taught learning, the pace of completion is determined by you. However, there will be an estimated time noted in each learning topic/course that indicates how long it should take to complete. This will help you manage your time effectively as you work through the course.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Can I download the course material?</Accordion.Header>
                            <Accordion.Body>
                                Yes, for ease of use, the four Absa ReadytoWork courses are available as a PDF download for offline learning purposes. Please note that, as a registered user, you cannot share, copy, amend or distribute the short course learning material or use it for your own commercial benefit.
                            </Accordion.Body>
                            <Accordion.Body>
                                Please note that for any courses designed by Enterprises University of Pretoria, you cannot share, copy, amend or distribute the short course learning material or use it for your own commercial benefit, as this would constitute a copyright infringement.
                            </Accordion.Body>
                            <Accordion.Body>
                                The following ReadytoWork modules are not under copyright and can be used as training material for volunteering purposes:
                                <ul>
                                    <li>People Skills</li>
                                    <li>Money Skills</li>
                                    <li>Work Skills</li>
                                    <li>Entrepreneurship.</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>How much do I need to achieve to pass the course?</Accordion.Header>
                            <Accordion.Body>
                                The pass rate for each Absa ReadytoWork course is 80%. You will not be able to download a certificate unless you achieve 80% or higher. There are no maximum attempts allowed, so you can repeat the course until you achieve the minimum pass rate, or if you simply need to do a refresher of the course.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>How do I download my certificate of completion?</Accordion.Header>
                            <Accordion.Body>
                                The certificate download option will be available on completion of each course once you have achieved an 80% pass rate. Click on the download certificate icon and save your certificate as a PDF document.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>How do I get a replacement certificate if I lost mine?</Accordion.Header>
                            <Accordion.Body>
                                To obtain a replacement certificate, you go to the specific short course and click on certificate download. You can download the certificate multiple times.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>How do I make changes to or update my App profile?</Accordion.Header>
                            <Accordion.Body>
                                You can view and edit your personal profile by clicking on the edit icon under your profile. This will allow you to upload a new profile picture, make edits and save the changes made.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>How do I connect with other ReadytoWork users?</Accordion.Header>
                            <Accordion.Body>
                                Click on the community connect icon at the bottom of the screen. The community connect screen will show and give you the option to invite, accept or decline invitations.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="11">
                            <Accordion.Header>How will my personal data be used?</Accordion.Header>
                            <Accordion.Body>
                                Your personal data is only used for the purposes of registering and auto-populating your profile, and for any communications related to Absa ReadytoWork enhancements or events. We recommend that you familiarise yourself with Absa’s Privacy Policy.
                            </Accordion.Body>
                            <Accordion.Body>
                                For more information: <span className="link" onClick={() => {
                                    window.open('https://www.absa.co.za/legal-and-compliance/privacy-statement/', '_blank');
                                }}>Absa's commitment to information Privacy Policy</span>
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="12">
                            <Accordion.Header>Can I block other users from seeing my personal details?</Accordion.Header>
                            <Accordion.Body>
                                You can choose which information on your profile is visible to other users under the security settings. You can select which of the following you want to hide from your public profile:
                                <ul>
                                    <li>Profile picture</li>
                                    <li>Bio</li>
                                    <li>Courses and education</li>
                                    <li>Skills</li>
                                    <li>Experience</li>
                                    <li>Contact</li>
                                    <li>Group chats</li>
                                    <li>Posts and shareability.</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="13">
                            <Accordion.Header>How does the job search work? Does Absa arrange a job for me?</Accordion.Header>
                            <Accordion.Body>
                                Absa ReadytoWork does not offer job opportunities or facilitate job application. However, users can search for jobs by clicking on the recruiter’s URL, which will take them directly to the recruiter’s website
                            </Accordion.Body>
                            <Accordion.Body>
                                Absa Bank is only providing URLs for recruitment agencies for your own purposes and you would need to engage directly with them. Absa is not affiliated with any of the recruitment agencies and cannot be held responsible for any loss or damages. Also, Absa does not guarantee that you will receive employment with any of the recruitment agencies with which that you engage. Once you click on any of the recruitment agency links, you will immediately leave the Absa domain site and be directed to the agency’s website. Absa cannot be held liable or take responsibility for your use of any of the recruitment agency(ies). 
                            </Accordion.Body>
                            <Accordion.Body>
                                We recommend that you always familiarise yourself with a service provider’s Privacy Policy, Disclaimer and Terms and conditions.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="14">
                            <Accordion.Header>What happens if I forget my password?</Accordion.Header>
                            <Accordion.Body>
                                You need to click on Forgot password. On the Forgot password screen, enter your email address and submit. Check your mailbox for the reset password email that will contain a link to reset your password. Choose and confirm your new password. You can now log back into Absa ReadytoWork.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="15">
                            <Accordion.Header>What is Online Career Guidance?</Accordion.Header>
                            <Accordion.Body>
                                Online Career Guidance is an online solution aimed at giving young individuals comprehensive career guidance, to help you make informed, realistic choices for either tertiary education or career decisions based on scarce skills, employability and jobs of the future.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="16">
                            <Accordion.Header>How does Online Career Guidance Work?</Accordion.Header>
                            <Accordion.Body>
                                It is an online evaluation that helps in identifying your interests and skills and to highlight the most appropriate career orientation for you in a comprehensive report. In addition to the online assessment, you will have access to other educational tools, videos and infographics that will provide more insights on career decisions.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="17">
                            <Accordion.Header>How much does Online Career Guidance Cost?</Accordion.Header>
                            <Accordion.Body>
                                Online Career Guidance is free to registered users of Absa ReadytoWork who access the Online Career Guidance website via the Absa ReadytoWork App.  If you access their website not using the URL embedded in the Absa ReadytoWork App, then you will be subject to their normal fees.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="18">
                            <Accordion.Header>How do I access the Online Career Guidance?</Accordion.Header>
                            <Accordion.Body>
                                Click on Online Career Guidance in the Absa ReadytoWork App.  You will leave the Absa ReadytoWork App and the Online Career Guidance website will open in a new window.
                            </Accordion.Body>
                            <Accordion.Body>
                                When you click on the Online Career Guidance URL, a disclaimer notice will appear, informing you that you will leave the Absa ReadytoWork App and be directed to the Online Career Guidance website.  Please familiarise yourself with the notice.
                            </Accordion.Body>
                        </Accordion.Item>
                        
                    </Accordion>
                </div>
            </div>
        </div>
    </>
}

export default FAQPage;
