import { FetchWrapper, LoginDetails } from "../_models/types";

const api = `${process.env.REACT_APP_API_URL}`;
const userType = `${process.env.REACT_APP_API_URL}/v1/user/update_user_type/`;

const signInUser = async (loginDetails: LoginDetails, fetchWrapper: FetchWrapper) => fetchWrapper.post(`${api}/api/token/`, loginDetails);
const updateRtwUserType = async (_: any, fetchWrapper: FetchWrapper) => fetchWrapper.post(`${userType}`, { user_type: 'rtw' });
const resetPassword = async (resetDetails: { email: string }, fetchWrapper: FetchWrapper) => fetchWrapper.post(`${api}/v1/password_reset/`, resetDetails);
export const authenticationService = {
    signInUser,
    updateRtwUserType,
    resetPassword
}
