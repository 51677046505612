import { scormModuleNames } from './scormStorage';

export const formatModuleName = (name: string): string => {
    let formatedName = "";
    const cleanName = name.replaceAll("_", " ");
    formatedName = `${cleanName[0].toUpperCase()}${cleanName.slice(1)}`;
    if (!scormModuleNames.includes(name)) {
        formatedName = `${formatedName} skills`;
    }
    return formatedName;
}

