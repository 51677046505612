import { FC, useContext, useEffect } from "react";
import { LearningModuleSummary } from "../_models/types";
import SCORMAdapter from "../_helpers/scormApiAdapter";
import useAPI from '../_hooks/useAPI';
import UserAuthStateContext from "../_contexts/userAuth.context";

declare global{
    interface Window { 
        API: any
    }
}

interface IProps {
    moduleSummary: LearningModuleSummary;
}
const ScormModuleView: FC<IProps> = ({ moduleSummary }) => {
    const { UserAuthState } = useContext(UserAuthStateContext);
    const fetchWrapper = useAPI();
    window.API = new SCORMAdapter(moduleSummary.name, UserAuthState, fetchWrapper);

    useEffect(() => {
        const commitPromise = fetchWrapper.post(
            `${process.env.REACT_APP_API_URL}/v1/scorm/commit/`, 
            {"module_name": moduleSummary.name, "data": "{}"}
        );
        commitPromise.then((response: any) => {
        if (!response.success) {
            console.log('Initial SCORM Commit Failed.')
        }
        }).catch((error: any) => {
            console.log(error)
        });

        const getValuePromise = fetchWrapper.get(
            `${process.env.REACT_APP_API_URL}/v1/scorm/init_data/?module_name=${moduleSummary.name}`, null
        );
        getValuePromise.then((response: any) => {
            window.localStorage.setItem('scormData', JSON.stringify(response));
        }).catch((error: any) => {
            console.log(error)
        });
    }, [])

    
    return <iframe id="scorm" src={`/${moduleSummary.name}/scormdriver/indexAPI.html`} height="700" frameBorder="0" title={"Scorm Render"} />
}

export default ScormModuleView;
