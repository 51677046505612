const AboutUsPage = () => {
  return (
    <>
      <div className='col-12 about-us'>
        <div className='row'>
          <div className='hero-image'>
            <img
              src='/images/L_ABOUT_us.jpg'
              alt=''
              className='w-100 img-fluid'
            />

            <div className='h-100 w-100 hero-image__title'>
              <div className="container text-center">
                <h3>What We Do</h3>
              </div>
            </div>
          </div>

          <div
            className='col-12 col-lg-8 offset-lg-2 about-us__head-txt'
            style={{ marginTop: '20px' }}
          >
            <p style={{ textAlign: 'left', textJustify: 'inter-word' }}>
            As young people set out to find employment or create self-employment through entrepreneurship they need skills that will help them to transition from the world of education into the world of work. Absa ReadytoWork is an education and skill development initiative designed to help young people go from learning to earning. Young people can register on the Absa ReadytoWork App to access online training courses for free.
            </p>
          </div>
          <div
            className='col-12 col-lg-8 offset-lg-2'
            style={{ marginTop: '20px' }}
          >        
            <h4>
            In the learning hub, the following core training courses are available:
            </h4>
            <div
              className='col-12'
              style={{ fontSize: '1.3rem', lineHeight: '35px' }}
            >
              <ul>
                <li>Work Skills to get yourself work ready.</li>
                <li>People Skills to find ways of working together and understanding people's emotions.</li>
                <li>Money Skills to help you manage your money.</li>
                <li>Entrepreneurial Skills if you dream of running your own business.</li>
              </ul>
            </div>
          </div>
  
          <div
            className='col-12 col-lg-8 offset-lg-2 about-us__head-txt'
            style={{ marginTop: '20px' }}
          >
            <p style={{ textAlign: 'left', textJustify: 'inter-word' }}>
            More advanced courses like Digital Literacy, Gig Economy, Blockchain Technologies, Computational Thinking (developed by a leading South African university), among other courses, are constantly being added.
            </p>
          </div>
          <div
            className='col-12 col-lg-8 offset-lg-2'
            style={{ marginTop: '20px' }}
          >
        
            <h4>
              Being a registered ReadytoWork user has other benefits too.
            </h4>
            <div
              className='col-12'
              style={{ fontSize: '1.3rem', lineHeight: '35px' }}
            >
              <ul>
                <li><strong>Via the resource tools</strong>, access to CV templates, information on how to create a digital profile and things you need to know about owning or buying a house or car.</li>
                <li>Articles and wellness videos can be accessed via our <strong>News and Wellness Corners.</strong></li>
                <li><strong>A job search tool</strong> enables searching for that dream job.</li>
                <li><strong>Free access to Online Career Guidance</strong>, an online solution that provides valuable guidance to help make informed and realistic career choices. A free online career guidance assessment will provide insight into the world of work, the jobs of the future – and the skills needed to fulfill them.</li>
                <li><strong>Connecting and networking</strong> with a community of like-minded people who are also registered on the ReadytoWork App and know how to make it work for them.</li>
                <li><strong>Celebrate success</strong> with insta-worthy Certificates of Completion for each course that will make a CV unskippable.</li>
                <li><strong>Side Hustles</strong> allows for exploring entrepreneurial ventures alongside traditional employment and embedding a forward-thinking approach to youth empowerment.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
