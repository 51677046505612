import { FetchWrapper } from "../_models/types";
const api = process.env.REACT_APP_API_URL;
const scorm = `${process.env.REACT_APP_API_URL}/v1/scorm/`;

const getInProgressCount = async (fetchWrapper: FetchWrapper) => fetchWrapper.get(`${scorm}get_in_progress_count/`);
const getCompletedCount = async (fetchWrapper: FetchWrapper) => fetchWrapper.get(`${scorm}get_completed_count/`);
const updateRtwUserType = async (fetchWrapper: FetchWrapper) => fetchWrapper.post(`${api}/v1/user/update_user_type/`, { user_type: 'rtw' });

export const scormService = {
    getInProgressCount,
    getCompletedCount,
    // to be relocated
    updateRtwUserType,
}
