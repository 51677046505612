import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import AlertWrapper from "../_helpers/alert-wrapper";
import { authenticationService } from "../_services";
import { history } from '../_helpers/history';
import useAPI from "../_hooks/useAPI";

const ForgotPasswordPage = () => {
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const [resetDetails, setlogInDetails] = useState({ email: "" });
    const [canSubmit, setCanSubmit] = useState(false);
    const [showResetSuccess, setShowResetSuccess] = useState(false)
    const fetchWrapper = useAPI();

    useEffect(() => {
        resetDetails ?
            setCanSubmit(
                !!(resetDetails.email && resetDetails.email.length)
            ) : setCanSubmit(false)
    }, [resetDetails])

    async function submitForm() {
        try {
            await authenticationService.resetPassword(resetDetails, fetchWrapper);
            setShowResetSuccess(true);
        } catch (error: any) {
            const errors = Object.keys(error);
            let errorString = "";
            if (errors.length) {
                errors.forEach(er => errorString += `${error[er]}\n`);
            } else { errorString = error };
            setErrorMsg(errorString);
        }
    }
    
    return <>
        <Modal show={showResetSuccess} onHide={() => setShowResetSuccess(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Reset link generated</Modal.Title>
            </Modal.Header>
            <Modal.Body>Email sent. Please check your inbox for further instructions.</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    history.navigate("login");
                    setShowResetSuccess(false)
                }
                }>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
        <div className="col-12 signin">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/L_ABOUT_us.jpg" alt="" className="w-100 img-fluid" />
                    <div className="h-100 w-100 hero-image__title">
                        <div className="container text-center">
                        <h3>
                            Reset Password
                        </h3></div>
                    </div>
                </div>
                <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    {
                        errorMsg && <AlertWrapper message={errorMsg} variant="danger" closeMsg={() => setErrorMsg(null)} />
                    }
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                onChange={(e) => setlogInDetails({ email: e.target.value })} />

                        </Form.Group>

                        <Button variant="primary" type="button" onClick={submitForm} disabled={!canSubmit}>
                            Reset Password
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    </>
}

export default ForgotPasswordPage;
