import { FC } from "react";
import { Alert, Button } from "react-bootstrap";

interface IProps {
    variant: 'success' | 'danger'
    message: string
    closeMsg: () => void
}

const AlertWrapper: FC<IProps> = ({ variant, message, closeMsg }) => {
    return (
        <Alert variant={variant} className={variant === `success` ? `alert-success` : `alert-danger`}>
            {message}
            <div className="d-flex justify-content-end">
                <Button onClick={closeMsg} variant="light">
                    Close
                </Button>
            </div>
        </Alert>
    )
}

export default AlertWrapper;
