import { FetchWrapper, User } from "../_models/types";
import Storage from "./scormStorage";
import errorDefinition from "./scormErrors";

export default class SCORMAdapter {
  moduleName: string;
  user: User;
  fetchWrapper: any;
  api: string;

  constructor (moduleName: string, user: User, fetchWrapper: FetchWrapper){
    this.moduleName = moduleName;
    this.user = user;
    this.fetchWrapper = fetchWrapper;
    this.api = `${process.env.REACT_APP_API_URL}/v1/scorm/`;
  }

  LMSInitialize() {
    Storage.setItem('Initialized', true);
    return true;
  }

  LMSFinish() {
    if (!this._isInitialized()) {
      this._setError(301);
      return false;
    }
    let _return = this.LMSCommit();
    Storage.setItem('Initialized', false);
    Storage.clearAll();
    return _return;
  }

  LMSGetValue(element: string) {
    if (!this._isInitialized()) {
      this._setError(301);
      return false;
    }
    let value = Storage.getItem(element);

    if (value === null){
      const getValuePromise = this.fetchWrapper.get(
        `${this.api}get_value/?key=${element}&module_name=${this.moduleName}`
      );
      getValuePromise.then((response: any) => {
          value = response
      }).catch((error: any) => {
          console.log(error)
      });
    }
    
    if (value === null) {
      this._setError(201);
      return "";
    }
    return value;
  }

  LMSSetValue(element: string, value: any) {
    if (!this._isInitialized()) {
      this._setError(301);
      return false;
    }
    Storage.setItem(element, value);
    return true;
  }

  async LMSCommit() {
    let data: any = Storage.getAll();
    delete data['errors'];
    delete data['Initialized'];
    data = JSON.stringify(data);

    const commitPromise = this.fetchWrapper.post(
      `${this.api}commit/`, 
      {"module_name": this.moduleName, "data": data}
    );
    commitPromise.then((response: any) => {
      if (!response.success) {
        console.log('SCORM Commit Failed.')
        this._setError(101);
        return false;
      }
    }).catch((error: any) => {
        console.log(error)
    });

    return true;
  }

  LMSGetLastError() {
    let errors = Storage.getItem('errors');
    errors = JSON.parse(errors);
    if (errors && errors.length > 0) {
      return errors.pop();
    }
    return 0;
  }

  LMSGetErrorString(errorCode: any) {
    errorCode = errorCode.toString();
    let error = errorDefinition[errorCode];
    if (!error) return "";
    return error["errorString"];
  }

  LMSGetDiagnostic(errorCode: any) {
    errorCode = errorCode.toString();
    let error = errorDefinition[errorCode];
    if (!error) return "";
    return error["diagnostic"];
  }

  _isInitialized() {
    let initialized = Storage.getItem('Initialized');
    return initialized;
  }

  _setError(errorCode: any) {
    errorCode = errorCode.toString();
    let errors = Storage.getItem('errors');
    if (!errors) errors = '[]';
    errors = JSON.parse(errors);
    errors.push(errorCode);
    errors = JSON.stringify(errors);
    Storage.setItem('errors', errors);
  }
}
