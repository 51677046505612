import { FetchWrapper } from "../_models/types";

const quizMap = (moduleName: string) => {
    switch (moduleName) {
        case "work":
            return 1;
        case "people":
            return 2;
        case "money":
            return 3;
        case "entrepreneurial":
            return 4;
        case "blockchain":
            return 5;
        case "computational_thinking":
            return 6;
        case "the_gig_economy":
            return 7;
        case "introduction_to_digital_literacy":
            return 8;
        case "tools_for_stress_management":
            return 9;
        case "embracing_change":
            return 10;
        case "time_management":
            return 11;
        case "creative_problem_solving":
            return 12;
        case "creative_thinking":
            return 17;
        default:
            break;
    }
}

const api = `${process.env.REACT_APP_API_URL}/v1/quiz/`;
const getQuizStatus = async (fetchWrapper: FetchWrapper, moduleName: string) => fetchWrapper.get(`${api}result/${quizMap(moduleName)}/`);
const resetQuizProgress = async (fetchWrapper: FetchWrapper, moduleName: string) => fetchWrapper.post(`${api}quiz/${quizMap(moduleName)}/reset_progress/`, null);
const getQuizByModule = async (fetchWrapper: FetchWrapper, moduleName: string) => fetchWrapper.get(`${api}quiz/${quizMap(moduleName)}/`)
const answerQuestion = async (fetchWrapper: FetchWrapper, payload: { option: string }, moduleName: string, question: number) => fetchWrapper.post(`${api}answer/${quizMap(moduleName)}/${question}/`, payload);
const getQuizResult = async (fetchWrapper: FetchWrapper, moduleName: string) => fetchWrapper.get(`${api}result/${quizMap(moduleName)}/`);
const getQuizResultMapped = async (fetchWrapper: FetchWrapper, moduleName: string) => fetchWrapper.get(`${api}result/${quizMap(moduleName)}/`);
const getCertificate = async (fetchWrapper: FetchWrapper, moduleName: string) => fetchWrapper.get(`${api}quiz/${quizMap(moduleName)}/get_certificate/`);
const rateQuiz = async (fetchWrapper: FetchWrapper, payload: { rating: number }, moduleName: string) => fetchWrapper.post(`${api}quiz/${quizMap(moduleName)}/rate/`, payload);

export const quizService = {
    getQuizStatus,
    resetQuizProgress,
    getQuizByModule,
    answerQuestion,
    getQuizResult,
    getCertificate,
    getQuizResultMapped,
    quizMap,
    rateQuiz,
    api
}
