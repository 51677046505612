import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CommonContextProvider } from './_contexts/common.context';
import * as amplitude from '@amplitude/analytics-browser';

amplitude.init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`, {
  defaultTracking: true,
  serverZone: 'EU',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <CommonContextProvider>
      <App />
      </CommonContextProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
