import React, { createContext, ReactNode, useContext } from 'react'

interface CommonContextProps {
  reloadPage: Function | any;
}

export const CommonContext = createContext<Partial<CommonContextProps>>({
	reloadPage: undefined,
})

interface CommonContextProviderProps {
  children: ReactNode
}

export const useCommonContext = () => useContext(CommonContext)

const CommonContextProvider: React.FC<CommonContextProviderProps> = ({
  children,
}) => {

  const reloadPage = () => {
    window.location.reload();
  };

  // Temporariy remove this to test impact on mobile usability.
  // useEffect(() => {
  //   window.addEventListener('resize', reloadPage)
  //   return () => {
  //     window.removeEventListener('resize', reloadPage)
  //   }
  // }, [])

  return (
    <CommonContext.Provider value={{ reloadPage }}>
      {children}
    </CommonContext.Provider>
  );
};

export { CommonContextProvider };
