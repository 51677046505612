import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserAuthStateContext from '../_contexts/userAuth.context';
import { history } from '../_helpers/history';


const PrivateRoute = ({ children, loadingUser }: any) => {
    const { UserAuthState } = useContext(UserAuthStateContext);
    if (!UserAuthState && !loadingUser) {
        return <Navigate to="/login" state={{ from: history.location }} />
    }
    return children;
}

export { PrivateRoute };
